<template>
  <div>
    <BCard>
      <b-row class="justify-content-between mb-[24px]">
        <b-col
          cols="auto"
          class="flex items-center"
        >
          <b-button
            variant="primary"
            size="sm"
            class="rounded-lg mr-2 my-auto"
            @click="$router.go(-1)"
          >
            <b-icon-chevron-left />
          </b-button>
          <h4>
            <strong class="text-black text-2xl">Input Kontak Baru</strong>
          </h4>
        </b-col>
      </b-row>
      <BOverlay
        id="infinite-list"
        :show="isLoading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div class="h-[100%]">
          <BTable
            id="table-pelanggan"
            :items="items"
            :fields="fields"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            class="mt-1"
          >

            <template #cell(phone_no)="data">
              <div class="min-w-[130px] text-[#222]">
                <new-phone-input
                  :check-wa="true"
                  @update:dataForParent="setCustomerPhone($event, data.index)"
                />
              </div>
            </template>

            <template #cell(name)="row">
              <div class="min-w-[130px] text-[#222]">
                <b-form-input
                  v-model="row.item.name"
                  placeholder=""
                  type="text"
                />
              </div>
            </template>

            <template #cell(location)="row">
              <div class="min-w-[150px] text-[#222]">
                <!-- <b-form-select
                  v-if="getAddress.length > 0"
                  v-model="row.item.location"
                  :options="getAddress"
                /> -->
                <v-select
                  v-if="getAddress.length > 0"
                  v-model="row.item.location"
                  :clearable="false"
                  :options="getAddress"
                  placeholder=""
                  class="form-address w-[200px]"
                  taggable
                  @search="searchAddressList"
                >
                  <template #no-options>
                    Kota yang kamu cari tidak ada
                  </template>
                </v-select>
                <b-form-input
                  v-else
                  v-model="row.item.location"
                  placeholder=""
                  type="text"
                />
              </div>
            </template>

            <template #cell(var_1)="row">
              <div class="max-w-[100px] text-[#222]">
                <b-form-input
                  v-model="row.item.var_1"
                  placeholder=""
                  type="text"
                  @input="checkVar(row.index)"
                  @keypress="isAlphabet($event)"
                />
              </div>
            </template>

            <template #cell(var_2)="row">
              <div class="max-w-[100px] text-[#222]">
                <b-form-input
                  v-model="row.item.var_2"
                  placeholder=""
                  type="text"
                  :disabled="!var_1Filled[row.index]"
                  @input="checkVar(row.index)"
                  @keypress="isAlphabet($event)"
                />
              </div>
            </template>

            <template #cell(var_3)="row">
              <div class="max-w-[100px] text-[#222]">
                <b-form-input
                  v-model="row.item.var_3"
                  placeholder=""
                  type="text"
                  :disabled="!var_1Filled[row.index] || !var_2Filled[row.index]"
                  @keypress="isAlphabet($event)"
                />
              </div>
            </template>

            <template #cell(action)="row">
              <div
                v-if="items.length > 1"
                class="w-[30px] text-center"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                  alt="image trash"
                  class="icon-close"
                  @click="deleteRow(row.item)"
                >
              </div>
            </template>
          </BTable>
          <BRow class="mt-1">
            <BCol
              cols="12"
              class="d-flex justify-content-between"
            >
              <div class="">
                <b-button
                  variant="outline-primary"
                  class="rounded-lg d-flex justify-content-center align-items-center gap-[8px]"
                  @click="addNewRow"
                >
                  <span>+</span>
                  <span>Tambah Baris</span>
                </b-button>
              </div>
            </BCol>
          </BRow>
        </div>
      </BOverlay>
      <BRow class="mt-1">
        <BCol
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            :variant="isInputEmpty ? 'secondary' : 'primary'"
            class="rounded-lg w-[auto]"
            :disabled="isInputEmpty"
            @click="sendData"
          >
            Simpan
          </b-button>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import newPhoneInput from '@/views/components/input/newPhoneInput.vue'
import { isAlphabet } from '@/libs/helpers'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    newPhoneInput,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: 'phone_no', label: 'Nomor WhatsApp', thClass: 'font-bold', class: 'align-middle text-black p-1',
        },
        {
          key: 'name', label: 'Nama', thClass: 'font-bold', class: 'align-middle text-black p-1',
        },
        {
          key: 'location', label: 'Lokasi', thClass: 'font-bold', class: 'align-middle text-black p-1',
        },
        {
          key: 'var_1', label: 'var 1', thClass: 'font-bold', class: 'align-middle text-black p-1',
        },
        {
          key: 'var_2', label: 'var 2', thClass: 'font-bold', class: 'align-middle text-black p-1',
        },
        {
          key: 'var_3', label: 'var 3', thClass: 'font-bold', class: 'align-middle text-black p-1',
        },
        {
          key: 'action', label: '', thClass: 'font-bold', class: 'align-middle text-black p-1',
        },
      ],
      items: [
        {
          country_code: '', phone_no: '', name: '', location: '', var_1: '', var_2: '', var_3: '',
        },
      ],
      isPhone: false,
      getPhone: [],
      getAddress: [],
      isAlphabet,
      var_1Filled: [],
      var_2Filled: [],
    }
  },
  computed: {
    isInputEmpty() {
      if (this.items.length === 1) {
        const firstRow = this.items[0]
        return (
          firstRow.name === ''
      || firstRow.location === ''
      || (this.isPhone && this.isPhone === true)
        )
      }

      return this.items.some(item => item.name === '' || item.location === '' || (this.isPhone && this.isPhone === true))
    },
  },
  mounted() {
    this.getAddressList()
  },
  methods: {
    addNewRow() {
      this.items.push({
        phone_no: '', name: '', location: '', var_1: '', var_2: '', var_3: '',
      })
      this.checkVar(this.items.length - 1)
    },
    checkVar(index) {
      const valItem = this.items[index]
      if (valItem.var_1 !== '') {
        this.var_1Filled[index] = true
      } else {
        this.var_1Filled[index] = false
        valItem.var_2 = ''
        valItem.var_3 = ''
      }
      if (valItem.var_2 !== '') {
        this.var_2Filled[index] = true
      } else {
        this.var_2Filled[index] = false
        valItem.var_3 = ''
      }
    },
    deleteRow(index) {
      this.items.splice(this.items.indexOf(index), 1)
    },
    async sendData() {
      const idGrup = parseInt(this.$route.params.grup_id, 10)
      const dataSend = this.items.map((item, index) => ({
        name: item.name,
        phone: `0${this.getPhone[index]}`,
        address: item.location,
        var1: item.var_1,
        var2: item.var_2,
        var3: item.var_3,
      }))
      const params = {
        group_contact_id: idGrup,
        contact: dataSend,
      }
      await this.$http_new.post('/komchat-api/api/v1/contact/create', params)
        .then(res => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sukses',
                icon: 'CheckIcon',
                text: 'Data berhasil ditambahkan!',
                variant: 'success',
              },
            },
            2000,
          )
          this.$router.go(-1)
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Data gagal ditambahkan!',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    setCustomerPhone(newVal, index) {
      this.getPhone[index] = newVal.customerPhone
      this.isPhone = newVal.requireCustomerPhone
    },
    getAddressList() {
      const getAdd = JSON.parse(localStorage.getItem('contactData')) || []
      const uniqueAddresses = []
      getAdd.forEach(item => {
        if (!uniqueAddresses.some(address => address === item.address)) {
          uniqueAddresses.push(item.address)
        }
      })
      this.getAddress = uniqueAddresses
    },
    searchAddressList() {},
  },
}
</script>

<style scoped>
.icon-close {
  cursor: pointer;
}
.table-responsive {
    height: calc(100vh - 330px) !important;
}
</style>
